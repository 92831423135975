export class CategoryAndMerchantModel {
    companyId: number;
    companyGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    categoryType: number;
    categoryId: number;
    status: number;
    locationId: string;
    stateId: number;
    countryId: any;
    recordType: number;
    isDefault: boolean = false;
    formType: number;
    searchText: string;
    
}

export class EmailApprovalM {
    claimId: any;
    claimGUID: any;
    userId: any;
    userGuid: any;
    roleId: any;
    ssoToken: any;
}

export class CategoryList {
    categoryId: number;
    categoryGUID: string;
    categoryName: string;
    categoryType: number;
    categoryTypeName: string;
    mileageRate: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    mileageRateCurrencyId: number;
    mileageUnit: string;
    showOnDashboard: boolean;
    merchantList: Array<MerchantObj>;
    isSelected = false;
    id: number;
    formType: string;
    formTypeName: string;
    isDefault: boolean = false;
    productId:any;
    orgcategoryName:any;
    categoryRestricted:any;
    isCategoryRestricted:any;
    isPolicyCalcPerNight: any;
    isStartEndDateEnabled: any;
    isSpentLocationManditory: any;
    categoryCode: any;
    isProofOfPaymentAttachmentRequired: boolean = false;
    isAutoCalculateDistanceAmount: boolean = true;
    mandateFromToLocations: boolean = false;
    isBillNumberManditory: boolean = false;
    isAttachmentMandatory: boolean = false;
    constructor(list: any) {
        this.categoryId = list.categoryId;
        this.categoryGUID = list.categoryGUID;
        this.categoryName = list.categoryName;
        this.categoryType = list.categoryType;
        this.categoryTypeName = list.categoryTypeName;
        this.mileageRate = list.mileageRate;
        this.mileageRateCurrencyCode = list.mileageRateCurrencyCode;
        this.mileageRateCurrencyIcon = list.mileageRateCurrencyIcon;
        this.mileageRateCurrencyId = list.mileageRateCurrencyId;
        this.mileageUnit = list.mileageUnit;
        this.showOnDashboard = list.showOnDashboard;
        this.merchantList = list.merchantList;
        this.id = list.id;
        this.formType = list.formType;
        this.formTypeName = list.formTypeName;
        this.isDefault = false;
        this.productId = list.productId;
        this.categoryRestricted = list.categoryRestricted;
        this.isCategoryRestricted = list.isCategoryRestricted;
        this.isPolicyCalcPerNight = list.isPolicyCalcPerNight;
        this.isStartEndDateEnabled = list.isStartEndDateEnabled;
        this.isSpentLocationManditory = list.isSpentLocationManditory;
        this.categoryCode = list.categoryCode;
        this.isProofOfPaymentAttachmentRequired = list.isProofOfPaymentAttachmentRequired;
        this.isAutoCalculateDistanceAmount = list.isAutoCalculateDistanceAmount;
        this.mandateFromToLocations = list.mandateFromToLocations;
        this.isBillNumberManditory = list.isBillNumberManditory;
        this.isAttachmentMandatory = list.isAttachmentMandatory;
    }
}
export class travelIds {
    id: any;
    code: any;
    formType: any;
    formTypeName: any;
    isSelected = false;
    constructor(list) {
        this.id = list.id;
        this.code = list.code;
        this.formType = list.formType;
        this.formTypeName = list.formTypeName;
        this.isSelected = list.isSelected;
    }
}

export class MerchantObj {
    merchantId: number;
    merchantGuid: string;
    merchantName: string;
}


export class MerchantList {
    merchantId: number;
    merchantGUID: string;
    merchantName: string;
    categoryId: number;
    categoryName: string;
    merchantCode: any;
    taxNumber: any;
    taxPercentage: any;

    constructor(list: any) {
        this.merchantId = list.merchantId;
        this.merchantGUID = list.merchantGUID;
        this.merchantName = list.merchantName;
        this.categoryId = list.categoryId;
        this.categoryName = list.categoryName;
        this.merchantCode = list.merchantCode;
        this.taxNumber = list.taxNumber;
        this.taxPercentage = list.taxPercentage;
    }
}

export class CurrencyList {
    currencyId: number;
    currencyCode: string;
    currencyIcon: string;
    conversionRate: number;
    currencyFormat: string;
    currencyFormatExample: string;

    constructor(list: any) {
        this.currencyId = list.currencyId;
        this.currencyCode = list.currencyCode;
        this.currencyIcon = list.currencyIcon;
        this.conversionRate = list.conversionRate;
        this.currencyFormat = list.currencyFormat;
        this.currencyFormatExample = list.currencyFormatExample;
    }
}

export class ImageBody {
    id: number;
    fileName: string;
    fileUploadFor: string;
    base64Image: string;
    pdfUrl: string;
}

export class filterM {
    type: string;
    index: any;
    value: string;
}

export class ExpenseAndReportModel {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    filterBy: FilterModal;
    pageNumber: number;
    pageSize: number;
    sortType: number;
    sortColumn: string;
    isDraft: boolean;
    recordType: number;
    stateId: number;
    isAdminView: boolean;
    viewType: number;
    linkedWithAdvance: any;
    isTripAdvance:boolean;
}

export class FilterModal {
    unassigned: boolean;
    categoryIds = [];
    statusIds = [];
    categoryType: number;
    searchText: string;
    fromDate: string;
    toDate: string;
    minPrice: number;
    maxPrice: number;
    isDraft: boolean;
    forUserIds = [];
    departmentIds = [];
    recordTypes = [];
    formTypes = [];
    ids = [];
    claimTypeId: any;
    claimTypeName: any;
    isCards: boolean;
    isTripAdvance: boolean;
}
export class TripFilterModal {
    unassigned: boolean;
    productIds = [];
    statusIds = [];
    categoryType: number;
    searchText: string;
    fromDate: string;
    toDate: string;
    // minPrice: number;
    // maxPrice: number;
    isDraft: boolean;
    statusAction: string;
    recordTypes = [];
    formTypes = [];
    ids = [];
}

export class StatusListModel {
    statusId: number;
    statusDescription: string;

    constructor(list: any) {
        this.statusId = list.statusId;
        this.statusDescription = list.statusDescription;
    }
}

export class filterListModel {
    statusId: number;
    statusName: string;
    productId: number;
    productName: string;
    constructor(list: any) {
        this.statusId = list.statusId;
        this.statusName = list.statusName;
        this.productId = list.productId;
        this.productName = list.productName;
    }
}

export class DownloadModel {
    userId: number;
    userGUID: string;
    jobId: number;
    jobGUID: string;
    referenceIds: string;
    entityType: number;
    filterBy: FilterModal;
    sortType: number;
    sortColumn: string;
    recordType: any;
    userPromt: any;
    dynamicReportType:any;

    isAdminView: boolean;
    isApprover: boolean;
    legalEntityGUID: string;
    legalEntityId: number;
    pageNumber: number;
    pageSize: number;
    viewType: number;
    docType: any;
    isSummaryView: boolean;
    linkedWithAdvance: any;
    isReportLevel: boolean = false;
    downloadActionType: any;
    jobType: any;
    isTripAdvance: any;
}

export class UserModel {
    userId: number;
    userGUID: string;
}

export class UserRcrdModel {
    userId: number;
    userGUID: string;
    recordType: number;
}

export class ITravellerObject {
    adults: number;
    children: number;
    infants: number;
    cabinClass: number;
    corporateUserList: any[];
    readOnly: boolean;
    extraOptions: any[];
    isAgentView: boolean;
    guestBooking: boolean;
    constructor() {
        this.adults = 0;
        this.children = 0;
        this.infants = 0;
        this.cabinClass = 1;
        this.corporateUserList = [];

    }
}

export class emulatedObj {
    legalEntityId: string | number;
    legalEntityGuId: string;
    companyId: string | number;
    companyGuId: string;
}

/*==========New Display Option Object==========*/
export class IOption {
    FamilyOrGuest: boolean;
    isCorporateSearch: boolean;
    isDomesticFlightOption: boolean;
    isInternationFlightOption: boolean;
    isMulticityOptionShowForDomestic: boolean;
    isMulticityOptionShowForInternational: boolean;
    isShowProject: boolean;
    showOfflineButton: boolean;
    isDomesticOffline: boolean;
    isInternationalOffline: boolean;
    isDomesticHotelOption: boolean;
    isInternationHotelOption: boolean;
    isGuestBooking: boolean;
    isFamilyBooking: boolean;
    constructor() {
        this.FamilyOrGuest = false;
        this.isCorporateSearch = false;
        this.isDomesticFlightOption = false;
        this.isInternationFlightOption = false;
        this.isMulticityOptionShowForDomestic = true;
        this.isMulticityOptionShowForInternational = true;
        this.isDomesticOffline = false;
        this.isInternationalOffline = false;
        this.isDomesticHotelOption = false;
        this.isInternationHotelOption = false;
        this.isGuestBooking = false;
        this.isFamilyBooking = false;
    }
}


export class SystemFieldsModel {
    legalEntityGUID: any;
    legalEntityId: any;
    companyId: any;
    companyGUID: any;
    formType: number;
    systemFieldList: any = [];
    customFieldList: any = [];
    searchText:string = '';
    claimTypeId: any;
    claimTypeName: any;
}

export class systemFieldsModel {
    fieldName: any;
    displayType: number;
    fieldId: any;
    isMandatory: any;    
    statusId: any;
    isReadOnly:any;
    category:any;
    categoryIds:any[] = [];
    selectedCat:any;
    claimTypeId:any;
    claimTypeName:any;
}

export class systemListModel {
    fieldName: any;
    displayType: number;
    fieldId: any;
    isMandatory: any;
    isReadOnly: any;
    statusId: any;
    constructor(list:any) {
        this.fieldName = list.fieldName;
        this.displayType = list.displayType;
        this.fieldId = list.fieldId;
        this.isMandatory = list.isMandatory;
        this.isReadOnly = list.isReadOnly;
        this.statusId = list.statusId;
    }
}

export class customFieldM{
    fieldName:any;
    displayType = 1;
    customFieldType:any;
    dropDownList:Array<string>;
    datePickerFormat:any;
    textFiledValidation:any;
    fieldId:null;
    isMandatory = false;
    statusId = 1;
    category:any;
    categoryIds = [];
    selectedCat:any;
    claimTypeId:any;
    claimTypeName:any;

}

